@import "../../../../sassStyles/mixins";

.aboutContainer {
  padding-block: 15rem;
  h2 {
    font-size: clamp(2.8rem, 10vw, 7.5rem);
  }
  .aboutInner {
    > div {
      @include flexContainer($justifyContent: flex-start, $gap: 4rem);
      > div {
        flex: 1 1 50%;
        h2 {
          font-weight: 500;
          line-height: 9rem;
          margin-bottom: 3.4rem;
        }
        p {
          font-size: 2.4rem;
          font-weight: 400;
        }
      }
    }
  }
  .featuresInner {
    margin-top: 15rem;
    text-align: center;
    > h2 {
      font-weight: 500;
    }
    > p {
      max-width: 90rem;
      margin: 0 auto;
      font-size: 2.4rem;
    }
    .featureCards {
      margin-top: 6.4rem;
      display: grid;
      grid-template-columns: repeat(3, 32%);
      gap: 2rem;
      > div {
        @include flexContainer(
          $alignItems: flex-start,
          $justifyContent: space-between
        );
        flex-direction: column;
        width: 100%;
        // flex: 1 1 calc(33.3% - 2rem);
        text-align: left;
        padding: 5rem 4rem !important;
        > div {
          img {
            width: auto;
          }
        }
        h3 {
        @include fontSize($minSize: 2.8rem, $maxSize: 5.6rem, $preferredSize: 5vw);
          font-weight: 400;
          line-height: 7rem;
          margin-bottom: 1.8rem;
        }
        p {
          margin-bottom: 1.8rem;
        }
        a {
          background-clip: text;
          color: transparent;
          padding: 0;
          text-align: left;
           font-size: 2.4rem;
        }
      }
    }
    .seeAllFeatures {
        text-align: center;
        margin-top: 6rem;
        a {
            color: var(--secondaryColor);
            text-decoration: underline;
            font-size: 2.2rem;
        }
    }
  }
}

@media only screen and (max-width: 990px) {
  .aboutContainer {
    .featuresInner {
      .featureCards {
        grid-template-columns: 48% 48%;
        justify-content: center;
        > div {
          h3 {
            line-height: normal;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .aboutContainer {
    padding-block: 12rem;
    .aboutInner {
      > div {
        flex-direction: column;
        padding: 4rem;
        > div {
          h2 {
            line-height: normal;
          }
        }
      }
    }
    .featuresInner {
      margin-top: 10rem;
      .featureCards {
        grid-template-columns: 1fr;
        > div {
          h3 {
            line-height: normal;
          }
        }
      }
    }
  }
}
