@import "../../../../sassStyles/mixins";

.productContainer {
  background-color: var(--secondaryColor);
  padding: 12rem 0;
  h2 {
    font-size: clamp(2.8rem, 10vw, 7.5rem);
  }
  .productInner {
    text-align: center;
    h2,
    p {
      color: var(--whiteColor);
    }
    h2 {
      font-weight: 500;
    }
    p {
      max-width: 90rem;
      margin: 2.4rem auto 0;
      font-size: 2.4rem;
    }
    .productBlock {
      @include flexContainer($gap: 4rem, $alignItems: flex-start);
      flex-direction: column;
      margin-top: 10rem;
      > div {
        text-align: left;
        padding: 6rem 7rem !important;
        width: 100%;
        position: relative;
        overflow: hidden;
        .appDownload {
          @include flexContainer($justifyContent: flex-start);
        }
        > div {
          max-width: 60%;
        }
        > img {
          position: absolute;
          width: 500px;
          bottom: 0;
          right: 40px;
          aspect-ratio: 1;
        }
        h4 {
          background: var(--primaryBgColor);
          background-clip: text;
          color: transparent;
          font-weight: 400;
          font-size: 3rem;
        }
        h3 {
          font-weight: 500;
          @include fontSize($minSize: 5rem, $maxSize: 6.5rem, $preferredSize: 10vw);
          color: var(--whiteColor);
        }
        p {
          font-size: 2rem;
          line-height: 3rem;
          font-weight: 400;
          margin-block-end: 2.4rem;
          max-width: 100%;
        }
        a {
          max-width: 20rem;
          padding-block: 1.8rem;
          font-size: 2rem;
        }
      }
      .merchantAppBlock {
        > img {
          right: unset;
          left: 40px;
          width: 450px;
        }
        > div {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .productContainer {
    .productInner {
      .productBlock {
        > div {
          > div {
            max-width: 100%;
          }
          > img {
            position: unset;
            width: 100% !important;
            margin-bottom: -56px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .productContainer {
    .productInner {
      .productBlock {
        > div {
          padding: 4rem 4rem 0 !important;
          > div {
            max-width: 100%;
            margin-bottom: 3rem !important;
          }
          > img {
            position: unset;
            width: 100%;
          }
        }
      }
      .merchantAppBlock {
        > img {
          width: 100% !important;
        }
      }
    }
  }
}
