.reviewCardContainer {
    border: 4px solid #c3e1f9;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 3px 0px 8px 1px rgb(202 202 202);
    position: relative;
}

.reviewCardContainer svg {
    fill: #0b88ee;
}

.quoteIcon {
    position: absolute;
    top: 6px;
    left: 6px;
}

.quoteIcon svg {
    font-size: 8rem;
    fill: #0b88ee29;
}

.customerName {
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #0b88ee;
    justify-content: center;
    align-items: center;
}

.customerName svg {
    font-size: 2.5rem;
}

.customerName p {
    font-weight: 600;
    font-size: 2.2rem;
    margin: 0;
}

.reviewBlock {
    z-index: 10;
    position: relative;
    min-height: 365px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 550px) {
    .reviewCardContainer {
        margin-bottom: 30px !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 950px) {
    .reviewCardContainer {
        max-width: 70%;
        margin: 0 auto 20px;
    }
}