@import "../../../sassStyles/mixins";

.headerContainer {
  padding-block: 4rem;
  // position: static;
  // top: 0;
  width: 100%;
  z-index: 9;
  height: 16.3rem;
  background-color: var(--secondaryColor);
  .headerInner {
    @include flexContainer($justifyContent: space-between);
    .siteLogo {
      img {
        max-width: 19.4rem;
      }
    }
    .navContainer {
      position: relative;
      .desktopHeader {
        margin: 0;
        padding: 0;
        list-style: none;
        @include flexContainer($justifyContent: flex-start, $gap: 6rem);
        li {
          color: var(--whiteColor);
          font-size: 2rem;
          font-weight: 300;
          position: relative;
          display: flex;
          align-items: center;
          gap: 5px;
          a {
            color: inherit;
            font-size: inherit;
          }
          &:hover {
            > .submenu {
              display: flex;
            }
          }
          .submenu {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #000;
            list-style: none;
            padding: 10px;
            margin: 0;
            bottom: -49px;
            width: 200px;
            display: none;
            flex-direction: column;
            gap: 10px;
            border: 1px solid var(--primaryColor);
            li {
              font-size: 18px;
            }
            &:hover {
              display: flex;
            }
          }
        }
      }
      > div {
        > div {
          display: none;
        }
      }
      .blackBg {
        background: rgb(0, 0, 0) !important;
      }
    }
  }
}

.staticHeader {
  position: static;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 16.3rem;
  background: transparent;
}

@media only screen and (max-width: 990px) {
  .headerContainer {
    .headerInner {
      .navContainer {
        > div {
          .desktopHeader {
            display: none;
          }
          > div {
            display: block;
          }
        }
      }
    }
  }
}
