@import "./sassStyles/mixins";

.pageContainer {
  @include flexContainer($justifyContent: flex-start, $alignItems: flex-start, $gap: 0);
  flex-direction: column;
  min-height: 100vh;
  > div {
    flex: 1 1 auto;
    width: 100%;
  }
}

.contentContainer {
  padding: 100px 0;
}

@media only screen and (max-width: 550px) {
  .contentContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
}