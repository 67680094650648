@import "../../sassStyles/mixins";

.faqBox {
  border: 1px solid #bbb;
  .faqTitle {
    @include flexContainer($justifyContent: space-between);
    padding: 4rem 6rem;
    cursor: pointer;
    h3 {
      color: var(--whiteColor);
      font-size: 4rem;
    }
  }
  .faqContent {
    padding: 4rem 6rem;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    p {
      color: var(--whiteColor);
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 550px) {
    .faqBox {
        .faqTitle {
            padding: 2rem;
            h3 {
                font-size: 2rem;
            }
        }
        .faqContent {
            padding: 2rem;
        }
    }
}
