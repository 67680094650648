/* .aboutContainer img {
    max-width: 320px;
} */

.aboutCol {
    padding: 20px;
}

.aboutColImg {
    text-align: center;
}

.aboutSubHeading {
    color: #fdb613;
    font-weight: 600;
    font-size: 1.6rem;
}

@media only screen and (max-with: 550px) {
    .aboutContainer {
        padding-top: 0px !important;
    }
}

.aboutContainer h2 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 25px;
}

.aboutSubContainer {
    margin-top: 40px;
}

.aboutSubContainer h2 {
    font-size: 3.5rem;
}

.issuesList {
    font-size: 1.8rem;
    list-style: disc;
}