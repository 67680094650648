@import '../../sassStyles/mixins';

.helpPageHeader {
    @include flexContainer;
    background-image: url(../../assets/bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 30rem;
    h2 {
        color: var(--whiteColor);
        font-size: clamp(2.4rem, 10vw, 7rem);
    }
}

.helpContainer {
    background-color: var(--secondaryColor);
    .helpInner {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); /* 3 columns by default */
        grid-gap: 30px; /* Spacing between boxes */
        padding: 20px 30px;
        max-width: 1160px; /* Limit container width */
        margin: 0 auto; /* Center the container */
    }
}