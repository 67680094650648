@import "./sassStyles/mixins";
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

:root {
  /* Global Colors */
  --primaryColor: rgb(247,150,23);
  --primaryBgColor: linear-gradient(256.88deg, #ffc90a 0%, #f7961d 100%);
  --secondaryColor: #000000;
  --whiteColor: #ffffff;
  --bgColor: #f2f2f2;
  --lightBgColor: rgba(255, 255, 255, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
}

html {
  font-size: 62.5%;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

/* Global Font Sizes */

h1 {
  @include fontSize($minSize: 5rem, $maxSize: 13.6rem, $preferredSize: 10vw);
}

h2 {
  @include fontSize($minSize: 2.8rem, $maxSize: 7.5rem, $preferredSize: 10vw);
}

p {
  @include fontSize($minSize: 1.6rem, $maxSize: 2.2rem, $preferredSize: 10vw);
}

a {
  text-decoration: none !important;
}

/* Global Classes */

.containerWidth {
  width: 100%;
  > div {
    width: 100%;
    max-width: 192rem;
    padding-inline: 12rem;
    margin: 0 auto;
  }
}

.defaultBtn {
  display: block;
  width: 100%;
    border-radius: 10rem;
    padding-block: 2.4rem;
    font-size: 2.8rem;
    font-weight: 500;
    background: var(--primaryBgColor);
    text-align: center;
    color: var(--secondaryColor);
}

button {
  a {
    color: inherit;
    font-size: inherit;
    letter-spacing: inherit;
  }
}

@media only screen and (max-width: 1280px) {
  .containerWidth {
    > div {
      padding-inline: 6rem;
    }
  }
}

@media only screen and (max-width: 550px) {
  .containerWidth {
    > div {
      padding-inline: 2rem;
    }
  }
  .defaultLightWrapper {
    border-radius: 7.2rem !important;
  }
}