.reviewsContainer h2 {
    font-size: 6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 70px;
}

.reviewCol {
    margin-bottom: 30px;
}

@media only screen and (max-width: 550px) {
    .reviewsContainer {
        padding-top: 100px;
    }

    .reviewsContainer h2 {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 950px) {
    .reviewCol {
        text-align: center;
    }
}