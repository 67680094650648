.logoBlock {
    margin-bottom: 70px;
}

.logoBlock img {
    max-width: 250px;
}

.bannerTextBlock h1 {
    font-size: 5.5rem;
    font-weight: 700;
    line-height: 1em;
    color: #000;
}

.bannerTextBlock h4 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1em;
    margin-top: 20px;
    color: #000;
}

.bannerTextBlock p {
    margin-top: 30px;
    padding-right: 10%;
    color: #000;
}

.bannerContainer {
    /* background-image: url('../../assets/photoThree.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
    padding: 100px 0;
    min-height: 700px;
    position: relative;
    background-color: #a4a4a4;
    z-index: -1;
}

/* .bannerContainer:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
} */

.bookBtn a {
    background-color: #fdb613;
    color: #fff;
    font-size: 1.8rem;
    padding: 7px 15px;
    border-radius: 5px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    max-width: 36%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.bannerImgContainer {
    padding-left: 35px;
}

@media only screen and (max-width: 950px) {
    .bannerContainer {
      padding-right: 20px;
      padding-left: 20px;
    }

    .bannerTextBlock h1 {
        font-size: 4.5rem;
    }
  }

  @media only screen and (max-width: 550px) {
    .bookBtn a {
        max-width: 70%;
    }

    .bannerImgContainer {
        padding-left: 0px;
        padding-top: 35px;
    }
  }