@import '../../../sassStyles/mixins';

.footerContainer {
    background-color: var(--secondaryColor);
    .footerInner {
        .topFooter {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 4rem;
            margin-block: 11rem;
            .footerCol1 {
                .siteLogo {
                    img {
                        max-width: 19.4rem;
                    }
                    margin-bottom: 2.7rem;
                }
                .footerDescription {
                    p {
                        color: var(--whiteColor);
                        margin-bottom: 2.7rem;
                        font-size: 1.6rem;
                        line-height: 2.8rem;
                    }
                    .footerSocial {
                        @include flexContainer($justifyContent: flex-start, $gap: 4rem);
                        a {
                            color: var(--whiteColor);
                            font-size: 2.5rem;
                        }
                    }
                }
            }
            > div {
                h4 {
                    color: var(--whiteColor);
                    font-size: 2.4rem;
                }
                ul {
                    margin: 5.6rem 0 0;
                    padding: 0;
                    list-style: none;
                    @include flexContainer($justifyContent: flex-start, $alignItems: flex-start, $gap: 3rem);
                    flex-direction: column;
                    li {
                        color: var(--whiteColor);
                        font-size: 1.8rem;
                        a {
                            color: inherit;
                            font-size: inherit;
                        }
                    }
                }
            }
        }
        .bottomFooter {
            border-top: 1px solid #A7A7A7;
            padding-block: 4rem;
            @include flexContainer($justifyContent: space-between, $alignItems: flex-start, $gap: 3rem);
            p, li, a {
                font-size: 1.5rem;
                color: var(--whiteColor);
            }
            ul {
            @include flexContainer($justifyContent: flex-start, $gap: 0.5rem);
            list-style: none;
            margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .footerContainer {
        .footerInner {
            .topFooter {
                grid-template-columns: 1fr 1fr;
            }
            // .bottomFooter {
            //     flex-direction: column;
            //     li, a {
            //         font-size: 1rem;
            //     }
            // } 
        }
    }
}

@media only screen and (max-width: 550px) {
    .footerContainer {
        .footerInner {
            .topFooter {
                grid-template-columns: 1fr;
            }
            .bottomFooter {
                flex-direction: column;
                li, a {
                    font-size: 1rem;
                }
            } 
        }
    }
}