@import '../../sassStyles/mixins';

.faqPageHeader {
    @include flexContainer;
    background-image: url(../../assets/bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 30rem;
    h2 {
        color: var(--whiteColor);
        font-size: clamp(2.4rem, 10vw, 7rem);
    }
}

.faqContainer {
    background-color: var(--secondaryColor);
    .faqInner {
        padding-block: 12rem;
    }
}