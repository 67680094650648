.privacyContainer {
  padding-block: 9rem;
  h1 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 3.8rem;
    margin: 3rem 0;
  }
  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 3.2rem;
    margin: 2rem 0;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 400;
  }
  ul {
    li {
      font-size: 1.6rem;
      line-height: 2.6rem;
      font-weight: 400;
    }
  }
  a {
    color: var(--primaryColor);
  }
}
