.menuBlock {
    position: relative;
    z-index: 2;
    .menuContainer {
        position: absolute;
        width: 30rem;
        height: 40rem;
        right: 0;
        border-radius: 1rem !important;
        background: rgb(0,0,0) !important;
        box-shadow: 0 0 8px rgb(255,255,255, 0.2);
        top: 40px;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4rem;
            align-items: center;
            li {
                font-size: 1.6rem;
                color: var(--whiteColor );
                a {
                    font-size: inherit;
                    color: inherit;
                }
            }
        }
    }
}