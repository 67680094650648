@import '../../../../sassStyles/mixins';

.commitContainer {
    padding-block: 12rem;
    h2 {
        font-size: clamp(2.8rem, 10vw, 7.5rem);
      }
    .commitInner {
        > div {
            text-align: center;
            // min-height: 100rem;
            > h2 {
                font-weight: 500;
            }
            > p {
                @include fontSize($minSize: 1.4rem, $maxSize: 3.2rem, $preferredSize: 10vw);
                margin-top: 2.4rem;
            }
            > img {
                mix-blend-mode: darken;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .commitContainer {
        .commitInner {
            > div {
                padding: 4rem !important;
                > p {
                    font-size: 2.4rem;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}