.benefits {
    display: flex;
    column-gap: 20px;
}

.benefitIcon svg {
    font-size: 4rem;
    fill: #fdb613;
}

.benefitText {
    padding-right: 20%;
}

.benefitText h4 {
    font-size: 2.4rem;
    font-weight: 600;
}

.contactForm form input, .contactForm form textarea {
    width: 100%;
    padding: 7px 15px;
    margin-bottom: 22px;
    font-size: 1.8rem;
    border: 2px solid #fdb613;
    border-radius: 5px;
}

.contactForm {
    background-color: #000;
    padding: 40px 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 80%;
    margin: -180px 0 0 auto;
}

.contactForm form button {
    width: 100%;
    background-color: #fdb613;
    color: #fff;
    font-size: 1.8rem;
    padding: 7px 15px;
    border-radius: 5px;
}

.contactForm h3 {
    text-align: center;
    font-size: 3.8rem;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 30px;
    color: #fdb613;
}

.contactForm label {
    color: #fff;
}

.select select {
    width: 100%;
    padding: 7px 15px;
    margin-bottom: 22px;
    font-size: 1.8rem;
    border: 2px solid #fdb613;
    border-radius: 5px;
}

.consentField {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 22px;
}

.consentField input {
    width: 20px !important;
    margin: 0 !important;
}

.consentField label {
    font-size: 1.8rem;
    margin: 0 !important;
}

.errorBlock {
    color: red;
    font-size: 1.4rem;
}

.marginZero {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 550px) {

    .contactForm {
        max-width: 100%;
        margin: 30px auto 0;
    }

    .benefitText {
        padding-right: 0;
    }

}

@media only screen and (max-width: 950px) {

    .contactForm {
        max-width: 100%;
    }

}

