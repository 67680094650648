.whoWeAreContainer {
    background-color: #000;
    text-align: center;
}

.whoWeAreContainer h2 {
    color: #fdb613;
    font-size: 6rem;
    font-weight: 600;
}

.whoWeAreContainer p {
    font-size: 2rem;
    margin-top: 20px;
    color: #fff;
}


@media only screen and (max-width: 550px) {
    .whoWeAreContainer h2 {
        font-size: 4rem;
    }

}

.btnContainer {
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.btnContainer a {
    background-color: #fdb613;
    color: #fff;
    font-size: 1.8rem;
    padding: 7px 15px;
    border-radius: 5px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    max-width: 36%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 200px;
}