@import "../../../../sassStyles/mixins";

.bannerContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 1080px;
  margin-top: -163px;
    @include backgroundImage($image: url(../../../../assets/bg.png));
  video {
    min-width: 100%;
    z-index: 1;
  }
  .bannerInner {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    @include flexContainer($justifyContent: flex-end);
    flex-direction: column;
    padding-top: 250px;
    // @include backgroundImage($image: url(../../../../assets/bg.png));
    h1 {
      font-size: clamp(5rem, 10vw, 13.6rem);
    }
    .bannerContent {
      width: 100%;
      @include flexContainer($gap: 4rem);
      flex-direction: column;
      h1,
      p {
        color: var(--whiteColor);
      }
      p {
        max-width: 80rem;
        margin: 0 auto;
        text-align: center;
        font-size: 2.4rem;
        line-height: 3.6rem;
      }
      a {
        max-width: 35rem;
      }
    }
    .bannerPointer {
      margin: 15rem 0 12rem;
      width: 100%;
      @include flexContainer($justifyContent: flex-start);
      > div {
        width: 100%;
        padding: 3rem !important;
        @include flexContainer($justifyContent: flex-start);
        p {
          @include fontSize(
            $minSize: 1.6rem,
            $maxSize: 2.2rem,
            $preferredSize: 1vw,
            $color: var(--whiteColor)
          );
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .bannerContainer {
    .bannerInner {
      .bannerPointer {
        flex-wrap: wrap;
        margin-top: 7rem;
        > div {
          flex: 1 0 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .bannerContainer {
    max-height: unset;
    height: 1300px;
    video {
      height: 100%;
    }
    .bannerInner {
      padding-top: 200px;
      height: unset;
      .bannerContent {
        a {
          max-width: 26rem;
        }
      }
      .bannerPointer {
        margin: 5rem 0 0;
        flex-direction: column;
        height: auto;
      }
    }
  }
}
