@import "../../sassStyles/mixins";

.helpBox {
  border: 1px solid #bbb;
  padding: 20px;
  text-align: center; 
  border-radius: 8px; 
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); 
  cursor: pointer;
  transition: transform 0.3s ease;
  .helpBoxTitle {
    color: var(--whiteColor);
    font-size: 2rem;
  }
  
  .helpBoxDescription {
    margin-top: 2rem;
    color: var(--whiteColor);
    font-size: 1.5rem;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  }

}

.lastOdd {
  grid-column: 1 / -1;
}
