@import '../../sassStyles/mixins';

.helpSectionHeader {
    @include flexContainer;
    background-image: url(../../assets/bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
        color: var(--whiteColor);
        font-size: clamp(2.4rem, 10vw, 7rem);
    }
    p {
        color: var(--whiteColor);
        font-size: clamp(1.4rem, 10vw, 3rem);
    }
}

.helpContainer {
    background-color: var(--secondaryColor);
    .links {
       display: flex;
       flex-direction: column;

       a {
           color: var(--whiteColor);
           font-size: 2rem;
           font-weight: 300;
           text-decoration: none;
           margin-top: 2rem;
            h3 {
                &:hover {
                color: var(--primaryColor);
                text-decoration: underline;
                }
            }
       }
    }
}